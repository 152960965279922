<template>
  <div class="first-page">
    <FirstPageHeader></FirstPageHeader>
    <router-view />
  </div>
</template>

<script>
import FirstPageHeader from "../../components/public/FristPageHeader.vue";
export default {
  components: { FirstPageHeader },
  data() {
    return {};
  },
};
</script>

<style lang="less">
::-webkit-scrollbar {
  display: none;
}
.first-page {
  width: 100%;
  height: 100%;
}
</style>