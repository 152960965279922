var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"first-page-header"},[_c('div',{staticClass:"tab-pc"},[_c('div',{staticClass:"tab-contain",class:[
        _vm.tabItemIndex == 0 || _vm.tabItemIndex == 1
          ? 'PageColorClass'
          : 'NoPageColorClass',
      ]},[_c('div',[_c('img',{staticStyle:{"width":"1.6rem","margin-right":"0.2rem"},attrs:{"src":_vm.tabItemIndex == 0 || _vm.tabItemIndex == 1
              ? require('../../assets/images/page1.png')
              : require('../../assets/images/page11.png')}}),_vm._l((_vm.tabList),function(item,index){return _c('div',{key:index,staticClass:"Rem0_22 tab-item",class:{
            tabItemActive: index == _vm.tabItemIndex,
            dispalyNo: !item.title,
          },on:{"click":function($event){return _vm.handleTab(index, item.path)}}},[_vm._v(" "+_vm._s(item.title)+" "),(item.child && item.child.length != 0)?_c('div',{directives:[{name:"scroll",rawName:"v-scroll",value:('animate__fadeIn'),expression:"'animate__fadeIn'"}],staticClass:"product-dialog2 animate__animated"},_vm._l((item.child),function(child,childIndex){return _c('p',{key:childIndex,class:{ activep: _vm.tabChildIndex == childIndex },staticStyle:{"white-space":"pre-wrap"},on:{"click":function($event){$event.stopPropagation();return _vm.clickTabLisChild(childIndex, child.path,index)}}},[_c('span',{staticClass:"triangle"}),_vm._v(" "+_vm._s(child.title)+" ")])}),0):_vm._e()])})],2),_c('div',[_vm._m(0),_c('el-button',{staticStyle:{"color":"#24569c","border":"1px solid #24569c !important"},on:{"click":function($event){return _vm.linkLogin(1)}}},[_vm._v("登录")]),_c('el-button',{staticStyle:{"background":"#24569c","color":"white"},on:{"click":function($event){return _vm.$router.push('./trial-use')}}},[_vm._v("申请使用")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"phone"},[_c('img',{staticStyle:{"width":"12px","margin-right":"5px"},attrs:{"src":require("../../assets/images/page2.png")}}),_c('span',{staticClass:"Rem0_22",staticStyle:{"cursor":"default"}},[_vm._v("020-39984322")])])
}]

export { render, staticRenderFns }